import React from 'react'
import TopContainer from '../components/TopContainer'
import BottomContainer from '../components/BottomContainer'

const Main = ({
    trackingStages,
    error
}) => {



  return (
    <main className="w-full min-h-screen flex flex-col md:flex-row  main-container justify-between text-white">
      <section className="flex-1 w-full">
        <TopContainer />
      </section>
      <section className="w-full md:w-3/5">
        <BottomContainer 
          trackingStages={trackingStages}
          error={error}
         />
      </section>
    </main>
  )
}

export default Main